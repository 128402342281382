.results_of_search{
   background: white;
   position: absolute;
   z-index: 99;
   margin: auto;
   align-self:center;
   border-radius: 15px;
   box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.saloon_search{
    background: white;
display: flex;
margin-top: 6px;
margin-bottom: 6px;
align-items: center;
width: 100%;
cursor: pointer;
}
.logo{
    width: 30px;
    height: 30px;
    margin-left: 15px;
}
.text{
    margin-left: 8px;
}
.adress{
    font-size: small;
}